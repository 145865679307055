<template>
  <div class="my-5 flex flex-col gap-6">
    <div class="flex gap-2 items-center">
      <div class="p-5 self-center bg-bb-blueberry">
        <ic-tag fill-color="#fff" />
      </div>
      <span class="text-bb-mid-grey">
        Connect your Google Merchant Center account used for this Google Ads account. Bidbrain will use this to fetch
        product data and optimize your Campaigns and product data.
      </span>
    </div>
    <div class="flex gap-2 items-center">
      <the-button
        @click.stop="showSupplementalFeedGuide"
        secondary
      >
        <template> Setup supplemental feed </template>
        <template #icon>
          <ic-cog />
        </template>
      </the-button>
    </div>
    <div class="flex justify-between pr-4 items-center">
      <div class="flex flex-wrap gap-3">
        <the-button
          @click.stop="$emit('updateStep', { step, index, incremental: -1 })"
          inner-class="w-20"
          secondary
        >
          Back
        </the-button>

        <the-button
          v-if="!step.completed && checkIfLastStep"
          @click.stop="checkSupplementalFeed"
          id="suppfeed_next"
          inner-class="w-32"
          primary
        >
          Complete
          <template #icon>
            <ic-check :size="20" />
          </template>
        </the-button>
        <the-button
          v-else-if="gmc_not_set"
          @click.stop="checkSupplementalFeed"
          id="suppfeed_next"
          inner-class="w-32"
          primary
        >
          Next
        </the-button>
        <the-button
          v-else
          @click.stop="$emit('updateStep', { step, index })"
          inner-class="w-32"
          primary
        >
          Next
        </the-button>
      </div>
    </div>
  </div>
</template>

<script>
import IcCog from 'vue-material-design-icons/Cog'
import IcCheck from 'vue-material-design-icons/Check'
import IcTag from 'vue-material-design-icons/Tag'
import SupplementalFeedGuideModal from '@/components/modals/SupplementalFeedGuideModal'

export default {
  name: 'supplemental-feed',
  components: { IcCog, IcCheck, IcTag },
  props: {
    step: {
      type: Object,
    },
    index: {
      type: Number,
    },
    checkIfLastStep: {
      type: Boolean,
    },
  },
  computed: {
    gmc_not_set() {
      return localStorage.getItem('GMC_NOT_SET')
    },
  },
  methods: {
    showSupplementalFeedGuide() {
      this.$modal.show(SupplementalFeedGuideModal, null, SupplementalFeedGuideModal.modalProps)
    },
    checkSupplementalFeed() {
      this.$gtm.trackEvent({
        event: 'onboarding_supp_feed',
        event_source: 'onboarding',
        project: 'console',
      })
      localStorage.removeItem('GMC_NOT_SET')
      this.$emit('updateStep', { step: this.step, index: this.index })
    },
  },
}
</script>
